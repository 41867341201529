import 'react-phone-number-input/style.css';

import countries from 'i18n-iso-countries';
import React from 'react';
import {Modal} from 'react-bootstrap';
import {isValidNumber} from 'libphonenumber-js';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import {genericTextChange} from '../../utils/forms';
import {InputFieldWithValidation} from '../fields_with_validation';
import AddressFields, {ascCountryCodes} from '../../constants/address_fields';
import TimeoutAlert from '../timeout_alert';
import {UserAddress} from "../../types/models/user_address";


interface EditAddressModalProps {
  existingAddress?: UserAddress | null;
  shown: boolean;
  onClose: Function;
  onSave: Function;
}

interface EditAddressModalErrors {
  fullName?: string[];
  phoneNumber?: string[];
  street?: string[];
  zip?: string[];
  unit?: string[];
  city?: string[];
  state?: string[];
}

// this component can change depending the region it is deployed at
export default function EditAddressModal({existingAddress, shown, onClose, onSave}: EditAddressModalProps) {
  const [fullName, setFullName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [street, setStreet] = React.useState('');
  const [zip, setZip] = React.useState('');
  const [unit, setUnit] = React.useState('');
  const [country, setCountry] = React.useState('SG');
  const [state, setState] = React.useState('');
  const [city, setCity] = React.useState('');

  const [fieldErrors, setFieldErrors] = React.useState<EditAddressModalErrors>({});
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const regExrCache = React.useRef<Record<string, RegExp>>({});

  React.useEffect(() => {
    if (!existingAddress) {
      return;
    }

    setFullName(existingAddress.fullName);
    setPhoneNumber(existingAddress.phoneNumber);
    setStreet(existingAddress.address);
    setZip(existingAddress.postalCode);
    setUnit(existingAddress.unitNumber);
    setState(existingAddress.state || '');
    setCity(existingAddress.city || '');
    if (existingAddress.country && AddressFields.countries[existingAddress.country]) {
      setCountry(existingAddress.country);
    } else {
      setCountry('SG');
    }
  }, [existingAddress]);

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCountry = e.target.value;
    setCountry(newCountry);
    // check state is in country
    const countrySettings = AddressFields.countries[country];
    const {stateRequired} = countrySettings;
    if (!stateRequired) {
      return;
    }

    const isStateInCountry = countrySettings.states[state];
    if (!isStateInCountry) {
      // default to first state in country
      setState(Object.keys(countrySettings.states)[0]);
    }
  };

  let isUpdate: boolean, title: string;
  if (existingAddress) {
    isUpdate = true;
    title = 'Edit Address';
  } else {
    isUpdate = false;
    title = 'Add Address';
  }

  const countryFieldSettings = AddressFields.countries[country];

  const validate = () => {
    const errors: Required<EditAddressModalErrors> = {
      fullName: [],
      phoneNumber: [],
      street: [],
      zip: [],
      unit: [],
      city: [],
      state: [],
    };

    if (fullName.length === 0) {
      errors.fullName.push('full name cannot be empty');
    }

    if (fullName.length > 200) {
      errors.fullName.push('full name length cannot be greater than 200');
    }

    if (!isValidNumber(phoneNumber)) {
      errors.phoneNumber.push('phone number is invalid');
    }

    if (street.length === 0) {
      errors.street.push('street cannot be empty');
    }

    if (street.length > 200) {
      errors.street.push('street length cannot be greater than 200');
    }

    if (countryFieldSettings.postalCodeRequired) {
      if (zip.length === 0) {
        errors.zip.push('zip code cannot be empty');
      }

      if (countryFieldSettings.postalCodeRegex !== null) {
        let postalCodeRegex = regExrCache.current[country];
        if (!postalCodeRegex) {
          postalCodeRegex = new RegExp(countryFieldSettings.postalCodeRegex);
          regExrCache.current[country] = postalCodeRegex;
        }

        if (!postalCodeRegex.test(zip)) {
          errors.zip.push('zip code is invalid');
        }
      }
    }

    if (unit.length === 0) {
      errors.unit.push('unit number cannot be empty');
    }

    if (countryFieldSettings.stateRequired) {
      if (city.length === 0) {
        errors.city.push('city cannot be empty');
      }
    }

    setFieldErrors(errors);
    const hasError = Object.values(errors).some(a => a.length > 0);
    return !hasError;
  };

  const handleSave = () => {
    const isValid = validate();
    if (!isValid) {
      return;
    }

    let savedCity = null;
    let savedState = null;
    if (countryFieldSettings.stateRequired) {
      savedCity = city;
      savedState = state;
    }

    const address: Partial<UserAddress> = {
      fullName,
      address: street,
      unitNumber: unit,
      postalCode: zip,
      phoneNumber: phoneNumber as string,
      country,
      city: savedCity,
      state: savedState
    };
    if (isUpdate) {
      address._id = existingAddress!._id;
    }

    onSave(address);
  };

  const handleClose = () => {
    setFieldErrors({});
    onClose();
  };

  const clearErrorOnChangeWrapper = <ET,>(errorKey: keyof EditAddressModalErrors, handler: (e: ET) => void) => (e: ET) => {
    const newFieldErrors = Object.assign({}, fieldErrors);
    delete newFieldErrors[errorKey];
    setFieldErrors(newFieldErrors);

    handler(e);
  };

  const countryMap = countries.getNames('en', {select: 'official'});

  return <Modal show={shown} onHide={handleClose} animation={false}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <TimeoutAlert
        errorMessage={errorMessage}
        onHide={() => setErrorMessage(null)}
      />

      <div className="form-group">
        <label>Full Name</label>
        <InputFieldWithValidation
          type="text"
          className="form-control"
          value={fullName}
          onChange={clearErrorOnChangeWrapper('fullName', genericTextChange(setFullName))}
          errors={fieldErrors.fullName}
        />
      </div>

      <div className="form-group">
        <label>Phone Number</label>
        <PhoneInput
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={(value) => {
            setPhoneNumber(value as string);
          }}
        />
      </div>

      <div className="form-group">
        <label>Address 1</label>
        <InputFieldWithValidation
          type="text"
          className="form-control"
          value={street}
          onChange={clearErrorOnChangeWrapper('street', genericTextChange(setStreet))}
          errors={fieldErrors.street}
          placeholder="Street address or P.O. Box"
        />
      </div>

      <div className="form-group">
        <label>Address 2</label>
        <InputFieldWithValidation
          type="text"
          className="form-control"
          value={unit}
          onChange={clearErrorOnChangeWrapper('unit', genericTextChange(setUnit))}
          errors={fieldErrors.unit}
          placeholder="Apt, suite, unit, building, floor, etc."
        />
      </div>

      <div className="form-group">
        <label>Zip Code</label>
        <InputFieldWithValidation
          type="text"
          className="form-control"
          value={zip}
          onChange={clearErrorOnChangeWrapper('zip', genericTextChange(setZip))}
          errors={fieldErrors.zip}
        />
      </div>

      <div className="form-group">
        <label>Country</label>
        <select
          value={country}
          onChange={handleCountryChange}
          className="form-control"
        >
          {ascCountryCodes.map((alpha2, index) => (
            <option value={alpha2} key={index}>{countryMap[alpha2]}</option>
          ))}
        </select>
      </div>

      {countryFieldSettings.stateRequired && (
        <>
          <div className="form-group">
            <label>{countryFieldSettings.stateDisplayValue !== null ? countryFieldSettings.stateDisplayValue : 'State'}</label>
            <select value={state} onChange={genericTextChange(setState)} className="form-control">
              {Object.values(AddressFields.countries[country].states).map((state, index) => (
                <option value={state.stateValue} key={index}>{state.displayValue}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>{countryFieldSettings.cityDisplayValue !== null ? countryFieldSettings.cityDisplayValue : 'City'}</label>
            <InputFieldWithValidation
              type="text"
              className="form-control"
              value={city}
              onChange={clearErrorOnChangeWrapper('city', genericTextChange(setCity))}
              errors={fieldErrors.city}
            />
          </div>
        </>
      )}
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-primary" onClick={handleSave}>Save</button>
      <button className="btn btn-secondary" onClick={handleClose}>
        Close
      </button>
    </Modal.Footer>
  </Modal>;
}

EditAddressModal.propTypes = {
  existingAddress: PropTypes.object,
  shown: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};