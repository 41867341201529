import React, {Dispatch, SetStateAction} from 'react';
import {useParams} from 'react-router-dom';
import Dinero from 'dinero.js';
import {has} from "lodash";
import {formatDinero, formatMoney} from '../../utils/number';
import {extractAuthError, extractAxiosError} from '../../utils/error';
import TimeoutAlert from '../timeout_alert';
import Api from '../../api/api';
import {formatDateTimeFromSeconds} from '../../utils/date';
import NextStepLabel from '../orders/next_step_label';
import {
  ORDER_AT_WAREHOUSE,
  ORDER_CANCELLED,
  ORDER_IN_TRANSIT,
  ORDER_PURCHASED,
  ORDER_RECEIVED,
  ORDER_STATUSES_LIST,
  STATUS_WITHOUT_SUPPORT_CHAT
} from '../../constants/order_statuses';
import EditAddressModal from '../address/edit_address_modal';
import VariantInfo from '../variant_info';
import AddJourneyToLogModal from '../orders/add_journey_to_log_modal';
import {toTitleCase} from '../../utils/string';
import {getSourceMarketPlaceUrl} from '../../utils/url';
import FlagOrderModal from '../orders/flag_order_modal';
import {PAYMENT_METHODS_PAY_NOW} from '../../constants/payment_methods';
import ChatWindow from '../orders/chat_window';
import HeadTitle from '../head_title';
import AdjustProductWeightOverrides from '../adjust_product_weight_overrides';
import AddressView from '../address_view';
import CancelOrderForm from "../orders/cancel_form";
import {UserAddress} from "../../types/models/user_address";
import {AnyOrder, JourneyLog, OrderV3, StaffFlag} from "../../types/models/order";
import Skeleton from 'react-loading-skeleton';
import {StaffUser} from "../../types/models/user";
import AwbDialog, {AwbItem} from "../orders/awb_dialog";
import {getProductByIdMap, ProductByIdMap} from "../../lib/order";
import {QuotationItem, QuotationItemSources, QuotationItemStatuses} from "../../types/models/quotation_item";
import {getQuotationItemVariantInfo} from "../../lib/quotation_item";
import useMessagingSocket from "../../hooks/use_messaging_socket";
import CapturePaymentDialog from "../orders/capture_payment_dialog";
import RefundDialog from "../orders/refund_dialog";
import {GetOrderRefundable, RefundOrderResponse} from "../../types/responses/order";
import RefundsSection from "../orders/refunds_section";

interface OrderAndProducts {
  order: AnyOrder;
  products: ProductByIdMap;
}

export default function ViewOrder() {
  const [orderAndProducts, setOrderAndProducts] = React.useState<OrderAndProducts | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string | null>(null);
  const [editingShippingAddress, setEditingShippingAddress] = React.useState(false);
  const [editingBillingAddress, setEditingBillingAddress] = React.useState(false);
  const [showAddJourney, setShowAddJourney] = React.useState(false);
  const [showFlag, setShowFlag] = React.useState(false);
  const [ownerInfo, setOwnerInfo] = React.useState<StaffUser | null>(null);
  const [staffFlags, setStaffFlags] = React.useState<StaffFlag[]>([]);
  const [showAwbDialog, setShowAwbDialog] = React.useState(false);
  const [showCapturePaymentDialog, setShowCapturePaymentDialog] = React.useState(false);
  const [refundable, setRefundable] = React.useState<GetOrderRefundable | null>(null);
  const [showRefundDialog, setShowRefundDialog] = React.useState(false);

  const onMessagingError = React.useCallback((message: string) => setErrorMessage(message), []);

  const socket = useMessagingSocket(onMessagingError);

  const params = useParams<{ orderRef: string }>();
  const {orderRef} = params;

  let order: AnyOrder | undefined = undefined;
  if (orderAndProducts) {
    order = orderAndProducts.order;
  }

  const fetchOrder = React.useCallback(() => {
    const orderPromise = Api.staff.order.get(orderRef).then(orderRes => {
      const {order} = orderRes.data;
      setOrderAndProducts({
        order,
        products: getProductByIdMap(order)
      });
      return order;
    });

    return Api.staff.order.getOrderRefundable(orderRef)
        .then(res => {
          setRefundable(res.data);
          return orderPromise;
        })
        .catch(e => {
          const errorMessage = extractAxiosError(e);
          setErrorMessage(errorMessage);
          return null;
        });
  }, [orderRef]);

  React.useEffect(() => {
    let active = true;
    const wrapStateSetter = <T,>(stateSetter: Dispatch<SetStateAction<T>>): Dispatch<SetStateAction<T>> => {
      return (newValue: SetStateAction<T>) => {
        if (!active) {
          return;
        }
        stateSetter(newValue);
      };
    };
    const setStateWrappers = {
      setErrorMessage: wrapStateSetter(setErrorMessage),
      setOwnerInfo: wrapStateSetter(setOwnerInfo),
      setStaffFlags: wrapStateSetter(setStaffFlags)
    };
    (async () => {
      const {
        setErrorMessage,
        setOwnerInfo,
        setStaffFlags,
      } = setStateWrappers;
      const order = await fetchOrder();
      if (!active || !order) {
        return;
      }

      try {
        const res = await Api.staff.user.getByEmail(order.owner);
        setOwnerInfo(res.data.user);
      } catch (error: any) {
        const errorMessage = extractAuthError(error);
        setErrorMessage(errorMessage);
      }

      if (!active) return;

      try {
        const res = await Api.staff.order.getStaffFlags(order.orderRef);
        setStaffFlags(res.data.staffFlags);
      } catch (error: any) {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      }
    })();

    return () => {
      active = false;
    };
  }, [fetchOrder]);

  const handleNextStep = React.useCallback(() => {
    if (!order) {
      return;
    }

    if (order.status === ORDER_PURCHASED && refundable?.isCaptured === false) {
      setShowCapturePaymentDialog(true);
    }

    if (order.status === ORDER_AT_WAREHOUSE) {
      setShowAwbDialog(true);
    } else {
      Api.staff.order.nextStage(order.orderRef)
        .then(fetchOrder)
        .then(() => {
          setSuccessMessage('Successfully moved order to next step');
        })
        .catch(error => {
          const errorMessage = extractAxiosError(error);
          setErrorMessage(errorMessage);
        });
    }
  }, [order, fetchOrder, refundable]);

  const handleSetStatusAsInTransit = React.useCallback((items: AwbItem[]) => {
    if (!order || order.status !== ORDER_AT_WAREHOUSE) {
      return;
    }

    setShowAwbDialog(false);
    Api.staff.order.nextStage(order.orderRef, {
      items
    })
      .then(fetchOrder)
      .then(() => {
        setSuccessMessage('Successfully moved order to next step');
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  }, [order, fetchOrder]);

  const handleResendInvoice = React.useCallback(() => {
    Api.staff.order.resendInvoice(orderAndProducts!.order.orderRef)
      .then(_ => {
        setSuccessMessage("Successfully resent invoice to customer");
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      });
  }, [orderAndProducts]);

  const showChangeBillingAddressModal = React.useCallback(() => {
    setEditingBillingAddress(true);
  }, []);
  const showChangeShippingAddressModal = React.useCallback(() => {
    setEditingShippingAddress(true);
  }, []);

  const handleSaveAddress = React.useCallback((isBilling: boolean) => (updatedAddress: UserAddress) => {
    if (!orderAndProducts) {
      return;
    }

    let addressKey: string;
    let addressLabel: string;
    if (isBilling) {
      addressKey = 'billingAddress';
      addressLabel = 'billing address';
    } else {
      addressKey = 'shippingAddress';
      addressLabel = 'shipping address';
    }
    const changes = {
      ...updatedAddress,
      isBilling,
    };

    const {order} = orderAndProducts;

    Api.staff.order.updateAddress(order.orderRef, changes)
      .then(_ => {
        const newOrder = {
          ...order,
          [addressKey]: updatedAddress
        };
        setOrderAndProducts({
          ...orderAndProducts,
          order: newOrder
        });
        setSuccessMessage("Successfully updated " + addressLabel);
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      })
      .finally(() => {
        if (isBilling) {
          setEditingBillingAddress(false);
        } else {
          setEditingShippingAddress(false);
        }
      });
  }, [orderAndProducts]);

  const handleAddJourneySave = React.useCallback((details: string) => {
    const {order} = orderAndProducts!;
    // call api
    Api.staff.order.addJourneyLog(order.orderRef, details)
      .then((res) => {
        // update order
        if (order!.transitSteps) {
          const newOrder = Object.assign({}, order, {
            transitSteps: [
              ...order!.transitSteps,
              res.data.transitStep
            ]
          });
          setOrderAndProducts({
            order: newOrder,
            products: orderAndProducts!.products
          });
        }
      })
      .catch((error) => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      })
      .finally(() => {
        setShowAddJourney(false);
      });
  }, [orderAndProducts]);

  const handleFlagResult = React.useCallback((message: string) => {
    const {order} = orderAndProducts!;
    Api.staff.order.flagOrder(order!.orderRef, message)
      .then(_ => {
        setSuccessMessage('Successfully flagged order');
      })
      .catch(error => {
        const errorMessage = extractAxiosError(error);
        setErrorMessage(errorMessage);
      })
      .finally(() => {
        setShowFlag(false);
      });
  }, [orderAndProducts]);

  const handleOrderCancelled = React.useCallback(async () => {
    await fetchOrder();
    setSuccessMessage('Successfully cancelled order');
  }, [fetchOrder]);

  const hideCapturePaymentDialog = React.useCallback(() => setShowCapturePaymentDialog(false), []);

  const handleCapturePayment = React.useCallback(() => {
    if (!refundable) {
      return;
    }

    Api.staff.order.capturePayment(orderRef)
        .then(() => {
          setSuccessMessage('Successfully captured payment for order');
          return fetchOrder();
        })
        .catch((error) => {
          const errorMessage = extractAxiosError(error);
          setErrorMessage(errorMessage);
        })
        .finally(() => setShowCapturePaymentDialog(false));
  }, [fetchOrder, orderRef, refundable]);

  const refundClickHandler = React.useCallback(() => setShowRefundDialog(true), []);
  const hideRefundDialog = React.useCallback(() => setShowRefundDialog(false), []);
  const handleRefundConfirm = React.useCallback(({order, ...refundInfo}: RefundOrderResponse) => {
    setShowRefundDialog(false);
    setOrderAndProducts({
      order,
      products: getProductByIdMap(order)
    });
    setRefundable(refundInfo);
  }, []);

  const isOrderCancellable = React.useMemo(() => {
    if (!order) {
      return false;
    }

    const isCancellableFromOrder = ![ORDER_CANCELLED, ORDER_AT_WAREHOUSE, ORDER_IN_TRANSIT, ORDER_RECEIVED].includes(order.status);
    if (!refundable) {
      return isCancellableFromOrder;
    }

    if (refundable.canRefund) {
      return order.status !== 'ORDER_CANCELLED';
    }
    return isCancellableFromOrder;
  }, [order, refundable]);

  const shouldShowCaptureButton = React.useMemo(() => {
    if (!order) {
      return false;
    }

    const isStatusCapturable = [ORDER_AT_WAREHOUSE, ORDER_IN_TRANSIT, ORDER_RECEIVED].includes(order.status);
    return isStatusCapturable && refundable?.isCaptured === false;
  }, [order, refundable]);

  const refundsTotalDinero = React.useMemo(() => {
    if (!order || !refundable) {
      return null;
    }

    const refundedTotal = refundable.lineItems.reduce((total, lineItem) => {
      return total + lineItem.refundedAmount;
    }, 0);

    return Dinero({
      ...order.totalCost,
      amount: refundedTotal
    });
  }, [refundable, order]);

  const refundsTotalStr = React.useMemo<string | null>(() => {
    if (!refundsTotalDinero) {
      return null;
    }

    return formatDinero(refundsTotalDinero);
  }, [refundsTotalDinero]);

  const totalCostStr = React.useMemo(() => {
    if (!order) {
      return '';
    }

    if (!refundsTotalDinero) {
      return formatMoney(order.totalCost.currency, order.totalCost.amount);
    }

    return formatMoney(order.totalCost.currency, order.totalCost.amount - refundsTotalDinero.getAmount());
  }, [order, refundsTotalDinero]);

  const overseasDeliveryStatusIndex = ORDER_STATUSES_LIST.indexOf(ORDER_IN_TRANSIT);
  let currentStatusIndex = -1;
  if (order) {
    currentStatusIndex = ORDER_STATUSES_LIST.indexOf(order.status);
  }
  const canChangeAddress = currentStatusIndex !== -1 && currentStatusIndex < overseasDeliveryStatusIndex;

  const disableNextButton = (order && !NextStepLabel.hasNextStep(order.status)) as boolean;
  let pageTitle = 'Order';
  if (order) {
    pageTitle += ` #${order.orderRef}`;
  }

  let transitSteps: JourneyLog[] = [];
  if (order && order.transitSteps) {
    transitSteps = order.transitSteps;
  }

  return <React.Fragment>
    <HeadTitle
      title={pageTitle}
    />

    <TimeoutAlert
      errorMessage={errorMessage}
      onHide={() => setErrorMessage(null)}
    />

    <TimeoutAlert
      errorMessage={successMessage}
      alertColor="success"
      onHide={() => setSuccessMessage(null)}
    />

    <h2>{pageTitle}</h2>
    {!order ? (
      <p>Loading...</p>
    ) : (
      <div className="row">
        <div className="col-md-8">
          <div className="mb-3">
            <h4>Order Information</h4>
            <div>
              <span className="text-muted">Payment Method:</span>
              {' ' + order.paymentMethod}
            </div>
            <div>
              <span className="text-muted">Status:</span>
              {' ' + toTitleCase(order.status.replace('_', ' '))}
            </div>
            <div>
              <div className="text-muted">
                Customer notes:
              </div>
              <strong id="orderNotes">{order.notes}</strong>
            </div>
          </div>

          <div className="mb-3">
            <h4>Cost Breakdown</h4>
            <table className="table" id="orderTable">
              <thead>
              <tr>
                <th>Product Name</th>
                <th>Cost</th>
              </tr>
              </thead>
              <tbody>
              {order.cartItems.map((cartItem, index) => {
                const product = orderAndProducts!.products.productById[cartItem.productId];
                const cost = order!.productCostBreakdown[cartItem._id];
                return (
                  <tr key={index}>
                    <td>
                      <a href={getSourceMarketPlaceUrl(product)} className="text-muted">
                        {product.sourceMarketPlace}
                      </a>
                      <br/>
                      <a href={`${process.env.REACT_APP_STORE_ENDPOINT}/product/${product.paramName}`}>
                        {product.name}
                      </a>
                      <br/>
                      <strong>Quantity:</strong>
                      {' '}
                      <strong>{cartItem.quantity}</strong>
                      <br/>
                      <VariantInfo
                        variants={product.variants}
                        variantPath={cartItem.variantPath}
                      />
                      {cartItem.message && (
                        <>
                          <strong>Message</strong>
                          <br/>
                          {cartItem.message}
                        </>
                      )}
                    </td>
                    <td>
                      {formatMoney(cost.currency, cost.amount)}
                    </td>
                  </tr>
                );
              })}

              {order.version === 3 && order.quotationItems.map((quotationItem, index) => {
                let itemNameLink;
                let itemSourceLink;
                let variantInfo;
                if (quotationItem.productSource === QuotationItemSources.internal) {
                  const internalItem = quotationItem as QuotationItem<QuotationItemStatuses, QuotationItemSources.internal>;
                  const product = orderAndProducts!.products.productById[internalItem.productSourceData.productId];
                  itemNameLink = <a href={`${process.env.REACT_APP_STORE_ENDPOINT}/product/${product.paramName}`}>
                    {product.name}
                  </a>;

                  let sourceLabel = product.sourceMarketPlace;
                  if (sourceLabel === 'local' && product.merchant) {
                    sourceLabel = product.merchant.name;
                  }
                  itemSourceLink =  <a href={getSourceMarketPlaceUrl(product)} className="text-muted">
                    {`${sourceLabel} (QUOTATION)`}
                  </a>;
                  variantInfo = getQuotationItemVariantInfo(quotationItem, product);
                } else {
                  const externalItem = quotationItem as QuotationItem<QuotationItemStatuses, QuotationItemSources.external>;
                  itemNameLink = <a href={externalItem.productSourceData.link}>
                    {quotationItem.name}
                  </a>;

                  itemNameLink = quotationItem.name;
                  itemSourceLink = <a href={externalItem.productSourceData.link}>EXTERNAL (QUOTATION)</a>;
                }

                const cost = (order as OrderV3).quotationCostBreakdown[quotationItem._id];
                return (
                  <tr key={index}>
                    <td>
                      {itemSourceLink}
                      <br/>
                      {itemNameLink}
                      <br/>
                      <strong>Quantity:</strong>
                      {' '}
                      <strong>{quotationItem.quantity}</strong>
                      <br/>
                      {variantInfo && <VariantInfo
                        {...variantInfo}
                      />}
                      <strong>Instructions</strong>
                      <br/>
                      <strong>{quotationItem.instructions}</strong>
                    </td>
                    <td>
                      {formatMoney(cost.currency, cost.amount)}
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td>Product Total</td>
                <td id="orderProductTotal">{formatMoney(order.productTotal.currency, order.productTotal.amount)}</td>
              </tr>
              {order.version === 1 && (
                <tr>
                  <td>Service Fee</td>
                  <td>{formatMoney(order.serviceFee.currency, order.serviceFee.amount)}</td>
                </tr>
              )}
              <tr>
                <td>Order Handling Cost</td>
                <td id="orderHandlingCost">{formatMoney(order.shippingCost.currency, order.shippingCost.amount)}</td>
              </tr>
              {order.voucherDiscount && (
                <tr>
                  <td>Voucher</td>
                  <td>{formatMoney(order.voucherDiscount.currency, order.voucherDiscount.amount)}</td>
                </tr>
              )}
              <tr>
                <td>Transaction Fee</td>
                <td>{formatMoney(order.transactionFee.currency, order.transactionFee.amount)}</td>
              </tr>
              <tr>
                <td>Tax Fee</td>
                <td id="orderTaxCost">{formatMoney(order.taxFee.currency, order.taxFee.amount)}</td>
              </tr>
              <tr>
                <td>Remote Area Delivery Charge</td>
                <td id="orderRADCost">{formatMoney(order.remoteAreaFee.currency, order.remoteAreaFee.amount)}</td>
              </tr>
              {refundsTotalStr && (
                  <tr>
                    <td>Total Refunds</td>
                    <td>({refundsTotalStr})</td>
                  </tr>
              )}
              <tr>
                <td>Total Cost</td>
                <td id="orderTotalCost">
                  {totalCostStr}
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          {orderAndProducts && (
              <RefundsSection
                  order={orderAndProducts.order}
                  products={orderAndProducts.products}
                  showRefundButton={!!refundable?.canRefund}
                  onRefundButtonClick={refundClickHandler}
                  isOrderCaptured={!!refundable?.isCaptured}
                  onRemovePreCaptureRefund={handleRefundConfirm}
              />
          )}


          {order.paymentMethod === PAYMENT_METHODS_PAY_NOW && has(order, ['gatewayData', 'transactions']) && (
            <div className="mb-3">
              <h4>PayNow Payment</h4>
              <div>
                <span className="text-muted">
                  Payment Due:
                  {' '}
                </span>
                {formatMoney(order.totalCost.currency, order.totalCost.amount)}
              </div>
              <table className="table">
                <thead>
                <tr>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                {order.gatewayData.transactions.map((trans, index) => {
                  return <tr key={index}>
                    <td>{trans.description}</td>
                    <td>{formatMoney(trans.amount.currency, trans.amount.amount)}</td>
                  </tr>;
                })}
                <tr>
                  <td>Amount Left:</td>
                  <td>{formatDinero(
                    order.gatewayData.transactions.reduce((total, trans) => {
                      return total.subtract(Dinero(trans.amount));
                    }, Dinero(order.totalCost))
                  )}</td>
                </tr>
                </tbody>
              </table>
            </div>
          )}

          <div className="mb-3">
            <h4>Journey Log</h4>
            {transitSteps.length > 0 ? (
              transitSteps.map((transitStep, index) => (
                <div key={index} className="mb-2">
                  <div className="text-muted">{formatDateTimeFromSeconds(transitStep.timestamp)}</div>
                  <div dangerouslySetInnerHTML={{__html: transitStep.details}} />
                </div>
              ))
            ) : (
              <p>The log is empty</p>
            )}
            <button className="btn btn-primary" onClick={() => setShowAddJourney(true)}>
              Add Journey Log
            </button>
          </div>

          <div className="mb-3">
            <h4>Flags</h4>
            <table className="table">
              <thead>
              <tr>
                <th>Time</th>
                <th>Message</th>
              </tr>
              </thead>
              <tbody>
              {staffFlags.map((flag, index) => (
                <tr key={index}>
                  <td>
                    {formatDateTimeFromSeconds(flag.createdAt)}
                  </td>
                  <td>
                    {flag.message}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>

          <div className="mb-3">
            <ChatWindow
              socket={socket}
              groupRef={`order_${orderRef}`}
              onErrorMessage={setErrorMessage}
              disabled={STATUS_WITHOUT_SUPPORT_CHAT.includes(order.status)}
            />
          </div>

          <div className="mb-3">
            <h4>Adjust Product Weight Override</h4>

            <AdjustProductWeightOverrides
              orderRef={orderRef}
              onError={setErrorMessage}
              onSuccess={setSuccessMessage}
            />
          </div>

          <div className="mb-3">
            <h4>Actions</h4>

            <div className="mb-2">
              <button className="btn btn-light mr-2" onClick={handleResendInvoice}>
                Resend Invoice
              </button>

              <button className="btn btn-light mr-2" onClick={handleNextStep} disabled={disableNextButton}>
                <NextStepLabel currentStatus={order.status}/>
              </button>

              {canChangeAddress && (
                <div className="btn-group mr-2">
                  <button className="btn btn-light" onClick={showChangeBillingAddressModal}>
                    Change billing
                  </button>
                  <button className="btn btn-light" onClick={showChangeShippingAddressModal}>
                    Change shipping address
                  </button>
                </div>
              )}

              {shouldShowCaptureButton && (
                  <button className="btn btn-light mr-2" onClick={() => setShowCapturePaymentDialog(true)}>
                    Capture Payment
                  </button>
              )}

              <button className="btn btn-light mr-2" onClick={() => setShowFlag(true)}>
                Flag Order
              </button>
            </div>

            <>
              <h5>Cancel Order</h5>
              {isOrderCancellable ? (
                  <CancelOrderForm
                      orderRef={orderRef}
                      onCancel={handleOrderCancelled}
                      onCancelError={setErrorMessage}
                      isRefundable={refundable?.canRefund}
                  />
              ) : (
                  'Order cannot be cancelled'
              )}
            </>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-3">
            <h4>Customer Information</h4>
            {ownerInfo ? (<>
              <div><span className="text-muted">Email:</span> {ownerInfo.email}</div>
              <div><span className="text-muted">First Name:</span> {ownerInfo.firstName}</div>
              <div><span className="text-muted">Last Name:</span> {ownerInfo.lastName}</div>
            </>) : (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
          </div>

          <div className="mb-3">
            <h4>Shipping Address</h4>

            <AddressView
              address={order.shippingAddress}
            />
          </div>

          <div className="mb-3">
            <h4>Billing Address</h4>

            <AddressView
              address={order.billingAddress}
            />
          </div>
        </div>
      </div>
    )}

    <EditAddressModal
      existingAddress={order && order.billingAddress}
      shown={editingBillingAddress}
      onClose={() => setEditingBillingAddress(false)}
      onSave={handleSaveAddress(true)}
    />

    <EditAddressModal
      existingAddress={order && order.shippingAddress}
      shown={editingShippingAddress}
      onClose={() => setEditingShippingAddress(false)}
      onSave={handleSaveAddress(false)}
    />

    <AddJourneyToLogModal
      show={showAddJourney}
      onHide={() => setShowAddJourney(false)}
      onSave={handleAddJourneySave}
      currentJourneyLog={transitSteps}
    />

    <FlagOrderModal
      show={showFlag}
      onHide={() => setShowFlag(false)}
      onFlag={handleFlagResult}
    />

    <AwbDialog
      show={showAwbDialog}
      onHide={() => setShowAwbDialog(false)}
      onSave={handleSetStatusAsInTransit}
    />

    {order && (
      <CapturePaymentDialog
          order={order}
          show={showCapturePaymentDialog}
          onHide={hideCapturePaymentDialog}
          onConfirm={handleCapturePayment}
      />
    )}

    {orderAndProducts && (
        <RefundDialog
            order={orderAndProducts.order as OrderV3}
            products={orderAndProducts.products as ProductByIdMap<OrderV3>}
            show={showRefundDialog}
            onHide={hideRefundDialog}
            onConfirm={handleRefundConfirm}
            refundable={refundable}
        />
    )}
  </React.Fragment>;
}
