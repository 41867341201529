import axios from 'axios';
import Api from './api';
import {store} from '../store/store';
import {actions} from '../store/actions';
import {replaceAll} from "../utils/string";

const AUTH_BASE_URL = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;
const SERVICE_BASE_URL = process.env.REACT_APP_STORE_SERVICE_BASE_URL;
let MESSAGING_BASE_URL = 'http://localhost:3007';
if (process.env.REACT_APP_MESSAGING_SERVICE_BASE_URL) {
  MESSAGING_BASE_URL = replaceAll(process.env.REACT_APP_MESSAGING_SERVICE_BASE_URL, 'ws:', 'http:');
  MESSAGING_BASE_URL = replaceAll(MESSAGING_BASE_URL, 'wss:', 'https:');
}

const autoRefreshToken = [function (response: { status: number; config: any; }) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error: { response: { status: number; config: any; }; }) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status !== 401) {
    return Promise.reject(error);
  }

  // try to refresh token
  console.log('authHeadersEndpoint trying to refresh token');
  return Api.auth.refreshAccessToken()
    .then((_) => {
      // retry request
      const errorReqConfig = error.response.config;
      return axios(errorReqConfig);
    })
    .catch(err => {
      console.log(err);
      store.dispatch(actions.user.processSignOut());
      return Promise.reject(err);
    });
}];

axios.defaults.withXSRFToken = true;
export const authEndpoint = axios.create({
  baseURL: AUTH_BASE_URL,
  withCredentials: true,
});

export const authHeadersEndpoint = axios.create({
  baseURL: AUTH_BASE_URL,
  withCredentials: true,
});
authHeadersEndpoint.interceptors.response.use(...autoRefreshToken as any);

export const serviceEndpoint = axios.create({
  baseURL: SERVICE_BASE_URL,
  withCredentials: true,
});

export const serviceHeadersEndpoint = axios.create({
  baseURL: SERVICE_BASE_URL,
  withCredentials: true,
});
serviceHeadersEndpoint.interceptors.response.use(...autoRefreshToken as any);

export const messagingEndpoint = axios.create({
  baseURL: MESSAGING_BASE_URL,
  withCredentials: true,
});
serviceHeadersEndpoint.interceptors.response.use(
  ...autoRefreshToken as any
);